import { BrowserRouter } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from "./ScrollToTop ";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  <BrowserRouter>
    <HelmetProvider>
<ScrollToTop/>
      <App />
    </HelmetProvider>
  </BrowserRouter>
  
);
