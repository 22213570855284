import { Grid } from "@mui/material";
import "./PlatformsStyle.css";
// import PlatformPages from "./PlatformPages";
import { Link  } from "react-router-dom";

const platforms = [
  {
    id: "1",
    platformImage: <img src="linkedin.svg" alt="" width="45px" />,
    platformname: "Linkedin",
    platformUrl: "/Linkedin",
    pageImg: "/linkedin.svg",
  },
  {
    id: "2",
    platformImage: <img src="snapchat.svg" alt="" width="45px" />,
    platformname: "Snapchat",
    platformUrl: "/Snapchat",
    pageImg: "/snapchat.svg",
  },
  {
    id: "3",
    platformImage: <img src="ted.svg" alt="" width="45px" />,
    platformname: "TED",
    platformUrl: "/TED",
    pageImg: "/ted.svg",
  },
  {
    id: "4",
    platformImage: <img src="likee.svg" alt="" width="45px" />,
    platformname: "Likee",
    platformUrl: "/Likee",
    pageImg: "/likee.svg",
  },
  {
    id: "5",
    platformImage: <img src="twitter.svg" alt="" width="45px" />,
    platformname: "Twitter",
    platformUrl: "/Twitter",
    pageImg: "/twitter.svg",
  },
  {
    id: "6",
    platformImage: <img src="instagram.svg" alt="" width="45px" />,
    platformname: "Instagram",
    platformUrl: "/Instagram",
    pageImg: "/instagram.svg",
  },
  {
    id: "7",
    platformImage: <img src="pinterest.svg" alt="" width="45px" />,
    platformname: "Pinterest",
    platformUrl: "/Pinterest",
    pageImg: "/pinterest.svg",
  },
  {
    id: "8",
    platformImage: <img src="facebook.svg" alt="" width="45px" />,
    platformname: "Facebook",
    platformUrl: "/Facebook",
    pageImg: "/facebook.svg",
  },
  {
    id: "9",
    platformImage: <img src="sharechat.svg" alt="" width="45px" />,
    platformname: "Sharechat",
    platformUrl: "/Sharechat",
    pageImg: "/sharechat.svg",
  },
  {
    id: "10",
    platformImage: <img src="buzzfeed.svg" alt="" width="45px" />,
    platformname: "Buzzfeed",
    platformUrl: "/Buzzfeed",
    pageImg: "/buzzfeed.svg",
  },
  {
    id: "11",
    platformImage: <img src="espn.svg" alt="" width="45px" />,
    platformname: "ESPN",
    platformUrl: "/ESPN",
    pageImg: "/espn.svg",
  },
  {
    id: "12",
    platformImage: <img src="flickr.svg" alt="" width="45px" />,
    platformname: "Flickr",
    platformUrl: "/Flickr",
    pageImg: "/flickr.svg",
  },
  
  {
    id: "13",
    platformImage: <img src="imdb.svg" alt="" width="45px" />,
    platformname: "Imdb",
    platformUrl: "/Imdb",
    pageImg: "/imdb.svg",
  },
];

function Supported() {
  return (
    <Grid container className="grid" spacing={3}>
      {platforms.map((platform, index) => (
        <Grid
          key={index}
          display="flow"
          style={{ marginLeft: "40px", marginRight: "40px" }}
        >
          <Link
            to={platform.platformUrl}
            state={{ icon : platform.pageImg,
              canonical : platform.platformUrl
            }}
          >
            <Grid>
              <div>
                {platform.platformImage}
                <p
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    marginTop: "10px",
                    marginBottom: "30px",
                  }}
                >
                  {" "}
                  {platform.platformname}
                </p>
              </div>
            </Grid>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
}

export default Supported;
