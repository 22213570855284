import * as React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { Divider } from "@mui/material";
import SearchBar from "../component/searchbar/SearchBar";
import Why from "../component/why/Why";
import PlatformCards from "../component/platforms/PlatformCards";

function Pinterest() {
  // const params = useParams();
  let { state } = useLocation();
  return (
    <>
      <Helmet>
        <title>Pinterest Video Downloader </title>
        <meta
          name="description"
          content="Download from Pinterest,free download and easy ,high quality , Pinterest downloader ,Pinterest video downloader,save video"
        />
        <link rel="canonical" href={`${state?.canonical}`} />
      </Helmet>

      <Divider />

      <div className="background">
        <div>
          <h1 style={{ paddingTop: "30px" }}>
            Download from Pinterest
            <img
              src={`${state?.icon}`}
              alt=""
              width="40px"
              style={{ marginLeft: "10px" }}
            />
          </h1>
        </div>
        <SearchBar />
        <PlatformCards />
      </div>
      {/* <div>
        <img src="/1-instagram.gif" alt="" />
      </div> */}
      <div style={{ marginTop: "350px" }}>
        <Why />
      </div>
    </>
  );
}

export default Pinterest;
