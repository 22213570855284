import SearchBar from "../../component/searchbar/SearchBar";
import Supported from "../../component/platforms/Platforms";
import { Helmet } from "react-helmet-async";
import ActionAreaCard from "../../component/pastelink/NewPasteLink";
import Why from "../../component/why/Why";
import "./HomeStyle.css";
// import AdSense from 'react-adsense';

import Properties from "../properties/Propertieeeeeee";

function Home() {
  return (
    <>
      <Helmet>
        <title>Any4Download</title>
        <meta
          name="description"
          content="Check our Homepage to use free online tool for download any videos or photos from popular social media for example download from instagram."
        />
        <link rel="canonical" href="/" />
      </Helmet>
      <div className="background">
        <h1 style={{paddingTop:"30px"}}>Online Video Downloader</h1>

        <SearchBar />
        <ActionAreaCard />
      </div>
      <Properties />
      <Why />
      <Supported />
    </>
  );
}

export default Home;
