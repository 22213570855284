import * as React from "react";
import { useParams} from "react-router-dom";
import Instagram from "../../platformPages/Instagram";
// import PlatformPages from "./PlatformPages";
import Linkedin from "../../platformPages/Linkedin";
import Snapchat from "../../platformPages/Snapchat";
import TED from "../../platformPages/TED";
import Twitter from "../../platformPages/Twitter";
import Pinterest from "../../platformPages/Pinterest";
import Facebook from "../../platformPages/Facebook";
import Sharechat from "../../platformPages/Sharechat";
import ESPN from "../../platformPages/ESPN";
import Likee from "../../platformPages/Likee";
import Buzzfeed from "../../platformPages/Buzzfeed";
import Flickr from "../../platformPages/Flickr";
import Imdb from "../../platformPages/Imdb";
// import Gag from "../../platformPages/Gag";




function RouteWrapper() {
  const { platformName } = useParams();
  const arrayOfComponent = {
    Instagram: <Instagram />,
    Linkedin: <Linkedin />,
    Snapchat: <Snapchat />,
    TED: <TED />,
    Likee: <Likee />,
    Twitter: <Twitter />,
    Pinterest: <Pinterest />,
    Facebook: <Facebook />,
    Sharechat: <Sharechat />,
    Buzzfeed: <Buzzfeed />,
    ESPN: <ESPN />,
    Flickr : <Flickr/>,
    Imdb : <Imdb/>,
    // Gag : <Gag/>

  };
  return arrayOfComponent[platformName];
}

export default RouteWrapper;
